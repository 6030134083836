<template>
    <div class="main_bd">
        <div class="head_title">Guest Center - User management</div>
        <div class="frm_controls search" style="margin: 30px 0">
            <span class="frm_input_box vcode with_counter counter_in append" style="display: inline-block; width: 300px; padding-right: 10px">
                <input class="frm_input" style="width: 100%" v-model="q" @keyup="search_guestcenter_keyup" placeholder="search by name, phone, email" />
            </span>
            <a-button type="primary" @click="search_guestcenter"> Search </a-button>
        </div>
        <div class="mod_default_box permission">
            <a-table :rowKey="(data) => data.new_id" :loading="is_loading" :columns="columns" :data-source="guestcenter_list" :pagination="false">
                <a slot="index" slot-scope="text, data">
                    <p>No.{{ data.index }}</p>
                </a>
            </a-table>
            <a-pagination style="margin-top: 25px" @change="turnPage" :pageSize="10" v-model="page_num"
                :total="total_count" show-less-items show-quick-jumper
                :show-total="(total) => `Total ${total_count} items`" />
        </div>
    </div>
</template>

<style>
</style>

<script>
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    const columns = [
        {
            title: 'No.',
            dataIndex: 'index',
            scopedSlots: { customRender: 'index' },
            width: 100
        },
        {
            title: 'Join date',
            dataIndex: 'create_time'
        },
        {
            title: 'User name',
            dataIndex: 'name'
        },
        {
            title: 'Phone',
            dataIndex: 'phone_num'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        // {
        //     title: 'Merchant ID',
        //     dataIndex: 'sid'
        // },
    ];

    export default {
        name: 'guest_center',
        components: {},
        data() {
            return {
                columns,
                page_num: 1,
                total_count: 0,
                total_page: 1,
                guestcenter_list: [],
                q: '',
                is_loading: false,
            };
        },
        beforeMount() {
            let _token = window.localStorage.getItem('token') || '';

            if (!_token) {
                window.location.replace('/login');
            }
        },
        mounted() {
            this.get_guest_center_list();
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({})
        },
        methods: {
            search_guestcenter_keyup(evt) {
                if (evt.keyCode === 13) {
                    this.get_guest_center_list();
                }
            },
            search_guestcenter() {
                this.get_guest_center_list();
            },
            turnPage(e) {
                this.get_guest_center_list();
            },
            async get_guest_center_list() {
                this.is_loading = true;
                let res = await this.getGuestCenterList({
                    page: this.page_num,
                    q: this.q
                });

                let { total_count, total_page, list } = res;

                this.guestcenter_list = list;
                this.total_page = total_page;
                this.total_count = total_count;
                this.is_loading = false;
            },
            ...mapActions({
                getGuestCenterList: 'guest/getGuestCenterList'
            }),
            ...mapMutations({
                set_msg: 'app/APP_SET_MSG'
            })
        }
    };
</script>
