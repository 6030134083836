<template>
    <div class="main_bd">
        <div class="head_title">Event management</div>
        <div class="create">
            <button class="create_btn" @click="toCreateDraw('')">Create a draw</button>
        </div>
        <div class="mod_default_box permission">
            <a-table :rowKey="(data) => data.id" :columns="columns" :data-source="draw_list" :pagination="false">
                <template slot="index" slot-scope="text, data">
                    <p>{{ data.idx }}</p>
                </template>
                <template slot="status" slot-scope="text, data">
                    <a-tag color="geekblue">
                        {{ eventStatus(data.event_status) }}
                    </a-tag>
                </template>
                <template slot="action" slot-scope="text, data">
                    <a-button type="primary" size="small" v-if="data.event_status == 0"
                        @click="changeEventStatus('publish', data)">
                        publish
                    </a-button>
                    <a-button type="primary" size="small" v-if="data.event_status == 1 && data.event_status != 2"
                        @click="changeEventStatus('unpublished', data)"
                        style="background-color: #7d7d7d; border-color: #7d7d7d">
                        unpublished
                    </a-button>
                    <a-button type="primary" size="small" v-if="data.event_status == 1"
                        @click="changeEventStatus('active', data)"
                        style="margin: 0 10px; background-color: #2c9100; border-color: #2c9100">
                        active
                    </a-button>
                    <a-button type="primary" size="small" v-if="data.event_status == 2"
                        @click="changeEventStatus('suspend', data)"
                        style="margin-right: 10px; background-color: #fd7858; border-color: #fd7858">
                        suspend
                    </a-button>
                    <a-button type="danger" size="small" v-if="data.event_status == 0"
                        @click="deleteEvent(data.id, data.event_name)" style="margin: 0 10px">
                        delete
                    </a-button>
                    <a-button type="primary" size="small" style="background-color: #6200eed9; border-color: #6200eed9"
                        @click="toDetail(data)">
                        details
                    </a-button>
                    <a-button size="small" style="color: rgba(98, 0, 238, 0.85); border-color: #6200eed9;margin-left: 10px;"
                        @click="showLog(data)">
                        log
                    </a-button>
                </template>
            </a-table>
            <a-pagination style="margin-top: 25px" @change="turnPage" :pageSize="page_size" v-model="page"
                :total="total_count" show-less-items show-quick-jumper
                :show-total="total => `Total ${total_count} items`" />
        </div>
        <div class="loading_layer" v-if="is_loading">
            <a-spin />
        </div>
        <a-modal :width="900" v-model="detailVisible" @ok="handleOk">
            <template slot="title">
                <div class="modal_head">
                    <div>
                        <div class="event_name">{{ curDraw.event_name }}</div>
                        <div>Create time {{ curDraw.create_time }}</div>
                    </div>
                    <div>
                        <div class="count"
                            v-if="curDraw.event_status == 2 || curDraw.event_status == 3 || curDraw.event_status == 4 || curDraw.event_status == 5">
                            <span>{{ notify_count }} followers / </span>
                            <span>{{ enrollment_count }} Enrollment</span>
                        </div>
                        <div>
                            <a-tag color="blue">
                                {{ eventStatus(curDraw.event_status) }}
                            </a-tag>
                        </div>
                    </div>
                </div>
            </template>
            <a-descriptions>
                <div class="detail_title" slot="title">Event details</div>
                <a-descriptions-item label="Type" :span="3">
                    {{ curDraw.event_type }}
                </a-descriptions-item>
                <a-descriptions-item label="URL" :span="3" v-if="curDraw.event_type == 'Private'">
                    <span>{{ curDraw.url }}</span>
                    <span style="font-size: 16px; padding-left: 5px; cursor: pointer;"><a-icon v-clipboard:copy="curDraw.url" v-clipboard:success="onCopySuccess" type="copy"></a-icon></span>
                </a-descriptions-item>
                <a-descriptions-item label="Description" :span="3">
                    {{ curDraw.event_description }}
                </a-descriptions-item>
                <a-descriptions-item label="Event publish time" :span="3">
                    {{ formatDateAmerica(curDraw.publish_time) }}
                </a-descriptions-item>
                <a-descriptions-item label="Event active time" :span="3">
                    {{ formatDateAmerica(curDraw.start_time) }} to {{ formatDateAmerica(curDraw.end_time) }}
                </a-descriptions-item>
                <a-descriptions-item label="Result notify time" :span="3">
                    {{ formatDateAmerica(curDraw.result_time) }}
                </a-descriptions-item>
                <a-descriptions-item label="Picture" :span="1">
                    <img :src="curDraw.event_photo_url" alt="" style="width: 100px; height: 100px" />
                </a-descriptions-item>
                <a-descriptions-item label="Merchant id">
                    {{ curDraw.user_name }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions v-if="curDraw.price !== 'Free'">
                <div class="detail_title" slot="title">Price details</div>
                <a-descriptions-item label="Price" :span="1">
                    {{ curDraw.price }}
                </a-descriptions-item>
                <a-descriptions-item label="Tax" :span="1">
                    {{ curDraw.tax }}
                </a-descriptions-item>
                <a-descriptions-item label="Service fee" :span="1">
                    {{ curDraw.service_fee_value }}
                </a-descriptions-item>
            </a-descriptions>
            <a-descriptions>
                <div class="detail_title" slot="title">Prize details</div>
                <a-descriptions-item label="Prize description" :span="3">
                    {{ curDraw.prize_description }}
                </a-descriptions-item>
                <a-descriptions-item label="How it works" :span="3">
                    {{ curDraw.prize_guidelines }}
                </a-descriptions-item>
                <a-descriptions-item label="Prize validity time" :span="3">
                    {{ parseTimeRange(curDraw) }}
                </a-descriptions-item>
                <a-descriptions-item label="Prize number" :span="3"> {{ curDraw.prize_count }} </a-descriptions-item>
            </a-descriptions>
            <a-descriptions v-if="curDraw.event_status == 3 || curDraw.event_status == 4">
                <div class="detail_title" slot="title">Winner & prize</div>
            </a-descriptions>
            <a-table v-if="curDraw.event_status == 3 || curDraw.event_status == 4" :rowKey="(data) => data.id"
                :columns="prizeColumns" :data-source="prize_list" :pagination="false" :expandIconAsCell="false"
                :expandIconColumnIndex="-1" :defaultExpandAllRows="true"
                :expandedRowKeys="prize_list.map((item) => (item.winner_cid !== item.owner_cid ? item.id : ''))">
                <template slot="index" slot-scope="text, data">
                    <p>{{ data.idx }}</p>
                </template>
                <template slot="owner" slot-scope="text, data">
                    <span v-if="data.winner_cid == data.owner_cid">{{ data.owner_phone_num }}</span>
                </template>
                <template slot="status" slot-scope="text, data">
                    <a-tag :color="data.winner_cid !== data.owner_cid ? '#7D7D7D' : 'geekblue'">
                        {{ data.winner_cid == data.owner_cid || data.status == 4 ? prizeStatus(data.status) : 'Send' }}
                    </a-tag>
                </template>
                <template slot="action" slot-scope="text, data">
                    <a-button style="color: #7427ff" type="link" @click="showTimeLog(data, 0)"> order </a-button>
                    <br/>
                    <a-button style="color: #7427ff" type="link" @click="showTimeLog(data, 1)"> time log </a-button>
                    <br/>
                    <a-button style="color: #7427ff" type="link" @click="toWithdraw(data)"> withdraw </a-button>
                </template>
                <template slot="expandedRowRender" slot-scope="record">
                    <div v-show="record.winner_cid !== record.owner_cid"
                        style="display: flex; justify-content: space-between; text-align: center">
                        <span style="flex: 0.5">Recipient</span>
                        <span style="flex: 1"></span>
                        <span style="flex: 1"></span>
                        <span style="flex: 1" slot="owner">{{ record.owner_phone_num }}</span>
                        <span style="flex: 1">
                            <a-tag color="blue">{{ prizeStatus(record.status) }}</a-tag>
                        </span>
                        <span style="flex: 1"></span>
                    </div>
                </template>
            </a-table>
            <a-modal :width="400" v-model="logVisible" :closable="false" @ok="handleLogOk">
                <a-descriptions v-if="time_log.isTime" title="Time log">
                    <a-descriptions-item label="Create time" :span="3">
                        {{ formatDateAmerica(time_log.create_time) }}
                    </a-descriptions-item>
                    <a-descriptions-item v-if="time_log.send_time" label="Send time" :span="3"> {{
                            formatDateAmerica(time_log.send_time)
                    }} </a-descriptions-item>
                    <a-descriptions-item v-if="time_log.apply_time" label="Apply time" :span="3"> {{
                            formatDateAmerica(time_log.apply_time)
                    }} </a-descriptions-item>
                    <a-descriptions-item v-if="time_log.expire_time" label="Expire time" :span="3"> {{
                            formatDateAmerica(time_log.expire_time)
                    }} </a-descriptions-item>
                    <a-descriptions-item v-if="time_log.withdraw_time" label="Withdraw time" :span="3"> {{
                            formatDateAmerica(time_log.withdraw_time)
                    }} </a-descriptions-item>
                    <a-descriptions-item v-if="time_log.withdraw_operate_user" label="Withdraw operate user" :span="3"> 
                        {{ time_log.withdraw_operate_user }} 
                    </a-descriptions-item>
                </a-descriptions>
                <a-descriptions v-else title="Order">
                    <a-descriptions-item label="Order id" :span="3">
                        <a @click="toOrderDetail" style="border-bottom: 1px solid #333">{{ time_log.oid }}</a>
                    </a-descriptions-item>
                    <a-descriptions-item label="Transaction id" :span="3"> {{
                            curDraw.price !== 'Free' ? time_log.transaction_id : 'N/A'
                    }} </a-descriptions-item>
                    <a-descriptions-item label="Transaction status" :span="3"> {{
                            curDraw.price !== 'Free' ? time_log.transaction_status : 'N/A'
                    }} </a-descriptions-item>
                </a-descriptions>
                <template slot="footer">
                    <a-button type="primary" @click="handleLogOk"
                        style="margin-right: 100px; background-color: #2c9100; border-color: #2c9100">
                        Done
                    </a-button>
                </template>
            </a-modal>
            <template slot="footer">
                <a-button type="primary" v-if="curDraw.event_status == 0" @click="toCreateDraw(curDraw.id)">
                    Edit
                </a-button>
                <a-button
                    v-if="(curDraw.event_status == 0 || curDraw.event_status == 1 || curDraw.event_status == 2) && (uname == 'minitableapp@gmail.com' || uname == 'nora@minitable.net')"
                    type="info"
                    @click="handleSendMessage(curDraw)"
                >
                    Send Message 
                </a-button>
                <a-button
                    v-if="(curDraw.event_status == 0 || curDraw.event_status == 1 || curDraw.event_status == 2) && (uname == 'minitableapp@gmail.com' || uname == 'nora@minitable.net')"
                    type="info"
                    @click="hanldeSendNotification(curDraw)"
                >
                    Send push notification
                </a-button>
                <a-button type="primary" @click="handleOk" style="background-color: #2c9100; border-color: #2c9100">
                    Done
                </a-button>
            </template>
        </a-modal>
        <a-modal :width="800" v-model="actionLogVisible" :closable="false" @ok="handleActionLogOk">
            <div style="text-align: center;">
                <h3>user log</h3>
            </div>
            <a-table :rowKey="(data) => data.create_time" :columns="logColumns" :data-source="action_log"
                :pagination="{ pageSize: 50 }" :scroll="{ y: 240 }" />
            <template slot="footer">
                <a-button type="primary" @click="handleActionLogOk"
                    style="margin-right: 300px; background-color: #2c9100; border-color: #2c9100">
                    Done
                </a-button>
            </template>
        </a-modal>
    </div>
</template>

<style scoped>
.loading_layer {
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0%;
    left: 0%;
    line-height: 500px;
    background: #fff;
    z-index: 100;
    opacity: 0.5;
}

.col_main .col_main_inner {
    width: 100%;
    margin: 0 auto;
}

.main_bd {
    padding: 0 45px;
    width: 100%;
    margin: 0 auto;
}

.mod_default_box {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 1px 2px hsl(0deg 0% 59% / 30%);
    padding: 20px 30px 30px;
}

.col-auto {
    width: 100%;
}

.create {
    height: 28px;
    margin: 26px 0;
}

.create_btn {
    width: 145px;
    height: 28px;
    border: none;
    border-radius: 8px;
    background-color: rgba(116, 39, 255, 1);
    color: rgba(255, 255, 255, 1);
    font-family: 'Helvetica';
    font-size: 16px;
    position: absolute;
    right: 45px;
}

.count {
    color: rgba(116, 39, 255, 1);
    font-family: 'Helvetica-Bold';
    font-size: 16px;
    margin-bottom: 15px;
}

.modal_head {
    display: flex;
    justify-content: space-between;
    margin: 35px 35px 0 0;
}

.event_name {
    color: rgba(116, 39, 255, 1);
    font-family: 'Helvetica-Bold';
    font-size: 24px;
    margin-bottom: 15px;
}

.detail_title {
    color: rgba(116, 39, 255, 1);
    font-family: 'Helvetica-Bold';
    font-size: 20px;
}
</style>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
const moment = require('moment-timezone');

const columns = [
    {
        title: 'No.',
        dataIndex: 'idx',
        scopedSlots: { customRender: 'index' },
        width: 100
    },
    {
        title: 'Type',
        dataIndex: 'event_type',
        width: 150
    },
    {
        title: 'Create time',
        dataIndex: 'create_time',
        width: 250
    },
    {
        title: 'Title',
        dataIndex: 'event_name',
        width: 300
    },
    {
        title: 'Merchant',
        dataIndex: 'store_name'
    },
    {
        title: 'Price',
        dataIndex: 'price'
    },
    {
        title: 'Status',
        dataIndex: 'event_status',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: 'More',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
    }
];
const prizeColumns = [
    {
        title: 'No.',
        dataIndex: 'idx',
        scopedSlots: { customRender: 'index' }
    },
    {
        title: 'Code',
        dataIndex: 'prize_code'
    },
    {
        title: 'Winner',
        dataIndex: 'winner_phone_num'
    },
    {
        title: 'Owner',
        dataIndex: 'owner_phone_num',
        scopedSlots: { customRender: 'owner' }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: 'More',
        dataIndex: 'action',
        align: 'center',
        scopedSlots: { customRender: 'action' }
    }
];
const logColumns = [
    {
        title: 'Time',
        dataIndex: 'create_time',
        width: 200,
    },
    {
        title: 'Action',
        dataIndex: 'action_type',
        width: 250,
    },
    {
        title: 'Operator',
        dataIndex: 'user_id',
        width: 200,
    },
];

export default {
    name: 'draw',
    components: {},
    data() {
        return {
            uname: '',
            send_notification_tested: 0,
            send_message_tested: 0,
            is_loading: false,
            detailVisible: false,
            logVisible: false,
            columns,
            page: 1,
            page_size: 10,
            curStatus: 'Unpublished',
            event_status: [
                {
                    label: 'Unpublished',
                    value: '0'
                },
                {
                    label: 'Published',
                    value: '1'
                },
                {
                    label: 'Active',
                    value: '2'
                },
                {
                    label: 'Drawn',
                    value: '3'
                },
                {
                    label: 'Closed',
                    value: '4'
                },
                {
                    label: 'Suspend',
                    value: '5'
                }
            ],
            curDraw: {},
            prizeColumns,
            prize_status: [
                {
                    label: 'Inactive',
                    value: '0'
                },
                {
                    label: 'Active',
                    value: '1'
                },
                {
                    label: 'Expired',
                    value: '2'
                },
                {
                    label: 'Used',
                    value: '3'
                },
                {
                    label: 'Withdraw',
                    value: '4'
                }
            ],
            time_log: {},
            timer: null,
            confirmModel: false,
            draw_id: undefined,
            actionLogVisible: false,
            logColumns
        };
    },
    beforeMount() {
        let _sid = window.localStorage.getItem('sid') || '';
        let _token = window.localStorage.getItem('token') || '';
        let _uname = window.localStorage.getItem('uname') || '';

        this.uname = _uname;

        if (!_sid || !_token) {
            window.location.replace('/login');
        }
    },
    mounted() {
        this.draw_id = this.$route.query.draw_id;
        if (this.draw_id){
            this.getDrawById({
                id: this.draw_id
            }).then(res => {
                this.toDetail(res.data);
            })     
        }
        this.is_loading = true;
        this.getDrawList({
            page: 1,
            page_size: this.page_size,
            cb: this.set_loading_cb
        });
    },
    beforeDestroy() {
        clearInterval(this.timer);
        this.timer = null;
    },
    computed: {
        ...mapGetters({
            draw_list: 'draw/get_draw_list',
            total_page: 'draw/get_total_page',
            total_count: 'draw/get_total_count',
            prize_list: 'draw/get_prize_list',
            enrollment_count: 'draw/get_enrollment_count',
            notify_count: 'draw/get_notify_count',
            action_log: 'draw/get_action_log'
        }),
        ...mapState({})
    },
    methods: {
        set_loading_cb() {
            this.is_loading = false;
        },
        ...mapActions({
            getDrawList: 'draw/getDrawList',
            updateEventStatus: 'draw/updateEventStatus',
            delEvent: 'draw/delEvent',
            getPrizeList: 'draw/getPrizeList',
            getDrawEnrollment: 'draw/getDrawEnrollment',
            updatePrizeStatus: 'draw/updatePrizeStatus',
            getDrawById: 'draw/getDrawById',
            get_action_log: 'draw/actionLog',
            sendNotification: 'draw/sendNotification',
            sendPushMessage: 'draw/sendPushMessage'
        }),
        ...mapMutations({
            set_msg: 'app/APP_SET_MSG'
        }),
        onCopySuccess() {
            this.$message.success('Successfully copied!');
        },
        showLog(data) {
            this.get_action_log(
                {
                    id: data.id
                }
            );
            this.actionLogVisible = true;
        },
        handleActionLogOk() {
            this.actionLogVisible = false;
        },
        //event_status
        eventStatus(event_status) {
            if (event_status === '') {
                return '';
            }
            for (let index = 0; index < this.event_status.length; index++) {
                if (this.event_status[index].value == event_status) {
                    return this.event_status[index].label;
                }
            }
            return '';
        },
        //prize_status
        prizeStatus(status) {
            if (status === '') {
                return '';
            }
            for (let index = 0; index < this.prize_status.length; index++) {
                if (this.prize_status[index].value == status) {
                    return this.prize_status[index].label;
                }
            }
            return '';
        },
        changeEventStatus(status, data) {
            //this.confirmModel = true;
            const _this = this;
            let title;
            let content;
            let okText;

            let params = { id: data.id };
            let toStatus = 0;
            if (status === 'publish') {
                title = 'Publish the event ahead?';
                content = h => <div><span>Once you publish {data.event_name}, the publish time will be updated. All users will see the event on Minipass App.</span><br /><span >Original publish time  <span style="color:rgba(116, 39, 255, 1);">{_this.formatDateAmerica(data.publish_time)}</span></span></div>;
                okText = 'Yes, publish now';
                toStatus = 1;
                params['publish_time'] = Date.parse(new Date());
                params['to_publish_time'] = Date.parse(new Date());
            } else if (status === 'unpublished') {
                title = 'Unpublish the event?';
                content = h => <div><span>{data.event_name} has already been published on Minipass App. If you unpublish it, the users who followed it will receive a notification about the cancellation. </span><br /><span style="color:red;">Act with caution </span></div>;
                okText = 'Yes, unpublish now';
                toStatus = 0;
                params['to_unpublished_time'] = Date.parse(new Date());
            } else if (status === 'active') {
                title = 'Active the event ahead?';
                content = h => <div><span>Once you active {data.event_name}, all users who followed it will receive an active notification. Users can join the draw after you activate it.</span><br /><span >Original active time  <span style="color:rgba(116, 39, 255, 1);">{_this.formatDateAmerica(data.start_time)}</span></span></div>;
                okText = 'Yes, active now';
                toStatus = 2;
                params['to_active_time'] = Date.parse(new Date());
            } else if (status === 'suspend') {
                title = 'Suspend the event?';
                content = h => <div><span>{data.event_name} has already been active on Minipass App. If you suspend it, the users who attend it will receive a notification about the cancellation. </span><br /><span style="color:red;">Can’t be undone, act with caution  </span></div>;
                okText = 'Yes, suspend now';
                toStatus = 5;
                params['to_suspend_time'] = Date.parse(new Date());
            }
            this.$confirm({
                title: title,
                content: content,
                okText: okText,
                okType: 'danger',
                cancelText: 'No, cancel',
                onOk() {
                    params['event_status'] = toStatus;
                    _this.is_loading = true;
                    _this.updateEventStatus({
                        ...params,
                        operate_user: window.localStorage.getItem('uname') || '',
                        afterdata: {
                            page: _this.page,
                            page_size: _this.page_size
                        },
                        cb: _this.set_loading_cb
                    });
                },
                onCancel() {
                    //this.confirmModel = false;
                },
            });

        },
        toDetail(data) {
            this.getDrawEnrollment({
                draw_id: data.id
            });
            if (data.event_status == 2) {
                this.timer = setInterval(() => {
                    this.getDrawEnrollment({
                        draw_id: data.id
                    })
                }, 1800000);
            }
            this.getPrizeList({
                draw_id: data.id
            });
            this.curDraw = { ...data };
            this.detailVisible = true;
        },
        handleOk() {
            this.detailVisible = false;
            clearInterval(this.timer);
            this.timer = null;
        },
        showTimeLog(data, isTime) {
            this.time_log = { ...data };
            this.time_log.isTime = isTime;
            this.logVisible = true;
        },
        handleLogOk() {
            this.logVisible = false;
        },
        toWithdraw(data){
            const _this = this;
            this.$confirm({
                title: 'Withdraw the prize?',
                content: h => <div><span>Once you withdraw this prize, the user won’t see this prize anymore.</span><br /><span style="color:red;margin-top:30px;margin-bottom:30px">Can’t be undone, act with caution </span><div >Prize id  <span style="color:rgba(116, 39, 255, 1);margin-left:85px">{data.prize_code}</span></div><div >Current status  <span style="color:rgba(116, 39, 255, 1);margin-left:40px">{_this.prizeStatus(data.status)}</span></div><div >Current owner <span style="color:rgba(116, 39, 255, 1);margin-left:40px">{data.owner_phone_num}</span></div></div>,
                okText: 'Yes, withdraw',
                okType: 'danger',
                cancelText: 'No, cancel',
                onOk() {
                    _this.is_loading = true;
                    const params = {};
                    params['id'] = data.id;
                    params['withdraw_time'] = Date.parse(new Date());
                    params['withdraw_operate_user'] = window.localStorage.getItem('uname') || '';
                    params['status'] = 4;
                    _this.updatePrizeStatus({
                        ...params,
                        afterdata: {
                            page: _this.page,
                            page_size: _this.page_size
                        },
                        cb: _this.set_loading_cb
                    });
                    _this.handleOk();
                },
                onCancel() {
                    //this.confirmModel = false;
                },
            });
        },
        deleteEvent(id, name) {
            const _this = this;
            this.$confirm({
                title: 'Delete the event?',
                content: h => <div><span>{name} is unpublished now. If you decide to delete it, you won’t see it in the list any more. </span><br /><span style="color:red;">Can’t be undone, act with caution </span></div>,
                okText: 'Yes, delete now',
                okType: 'danger',
                cancelText: 'No, cancel',
                onOk() {
                    _this.is_loading = true;
                    _this.delEvent({
                        id,
                        operate_user: window.localStorage.getItem('uname') || '',
                        afterdata: {
                            page: _this.page,
                            page_size: _this.page_size
                        },
                        cb: _this.set_loading_cb
                    });
                },
                onCancel() {
                    //this.confirmModel = false;
                },
            });
        },
        turnPage(page) {
            this.page = page;
            this.getDrawList({
                page,
                page_size: this.page_size
            });
        },
        toCreateDraw(id) {
            const has_edit_id = window.localStorage.getItem('has_edit_id');
            if (id == has_edit_id) {
                this.$message.error('Edit not available');
                return
            }
            this.$router.push({
                    path: '/createDraw',
                    query: {
                        draw_id: id
                    }
                });
        },
        formatDateAmerica(time) {
            return moment.tz(time * 1, 'America/New_York').format('MM/DD/YYYY HH:mm A');
        },
        parseTimeRange(curDraw) {
            const sTime = curDraw.prize_active_stime;
            const eTime = curDraw.prize_active_etime;
            if (!sTime && !eTime) {
                return ' Unlimited validity!';
            } else if (!sTime && eTime) {
                return ' Valid until ' + this.formatDateAmerica(eTime);
            } else if (sTime && !eTime) {
                return ' Start at ' + this.formatDateAmerica(sTime);
            } else {
                return this.formatDateAmerica(sTime) + ' to ' + this.formatDateAmerica(eTime);
            }
        },
        hanldeSendNotification(data) {
            this.$confirm({
                title: 'Are you sure to send push information?',
                content: 'Operation cannot be canceled',
                okText: '全量用户',
                okType: 'danger',
                cancelText: '测试用户',
                onOk: async () => {
                    if (this.send_notification_tested == 0) {
                        this.$message.error('Please send to test user first!');
                        return;
                    }

                    let res = await this.sendNotification({
                        sid: data.sid,
                        draw_id: data.id,
                        event_name: data.event_name,
                        sname: data.store_name,
                        is_test: 0
                    });

                    if (res.code == 100000) {
                        this.$message.success('发送成功，请关注手机推送');
                    } else {
                        this.$message.error(res.msg);
                    }
                },
                onCancel: async () => {
                    let res = await this.sendNotification({
                        sid: data.sid,
                        draw_id: data.id,
                        event_name: data.event_name,
                        sname: data.store_name,
                        is_test: 1
                    });

                    if (res.code == 100000) {
                        this.send_notification_tested = 1;
                        this.$message.success('测试用户发送成功，请关注手机推送');
                    }
                },
            });
        },
        handleSendMessage(data) {
            this.$confirm({
                title: 'Are you sure to send message?',
                content: 'Operation cannot be canceled',
                okText: '全部排队用户',
                okType: 'danger',
                cancelText: '测试用户',
                onOk: async () => {
                    if (this.send_message_tested == 0) {
                        this.$message.error('Please send to test user first!');
                        return;
                    }

                    let res = await this.sendPushMessage({
                        sid: data.sid,
                        draw_id: data.id,
                        event_name: data.event_name,
                        active_end_time: data.end_time,
                        sname: data.store_name,
                        is_test: 0
                    });

                    if (res.code == 100000) {
                        this.$message.success('发送成功，请关注手机推送');
                    } else {
                        this.$message.error(res.msg);
                    }
                },
                onCancel: async () => {
                    let res = await this.sendPushMessage({
                        sid: data.sid,
                        draw_id: data.id,
                        event_name: data.event_name,
                        active_end_time: data.end_time,
                        sname: data.store_name,
                        is_test: 1
                    });

                    if (res.code == 100000) {
                        this.send_message_tested = 1;
                        this.$message.success('测试用户发送成功，请关注手机推送');
                    }
                },
            });
        },
        toOrderDetail() {
            this.$router.push({
                path: '/drawOrder',
                query: { order_id: this.time_log.oid }
            });
        },
    }
};
</script>
