import axiosApi from '../../kits/axiosApi';
import { message } from 'ant-design-vue';

// initial state
const state = {
};

// getters
const getters = {
};

// actions
const actions = {
    getMerchantList({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/merchant/list?search=` + post_data.search + '&page=' + post_data.page + '&page_size=20')
                .then((res) => {
                    resolve(res.data.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    updateMerchantStatus({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'http://127.0.0.1:5050' : '';

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/minipass/merchant/status/update?sid=` + post_data.sid + '&enable=' + post_data.enable + '&type=' + post_data.type)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
};

// mutations
const mutations = {
    
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
